<template>
  <div id="container" v-bind:class="colMode">
    <div class="menu-item" v-bind:class="bgColor">
      <div v-if="!!image">
        <!-- style="transform:{{ transform|default('') }};" -->
        <img id="img1" v-bind:src="imagePath" />
      </div>
      <div v-if="!!image2">
        <!-- style="transform:{{ transform|default('') }};" -->
        <img id="img2" v-bind:src="image2Path" />
      </div>
      <router-link id="name" v-bind:class="fgColor" v-bind:to="link">
        {{ name }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "MenuItem",
  props: {
    name: {
      type: String,
      required: true
    },
    size: {
      type: Number,
      default: 0
    },
    textcolor: {
      type: String,
      default: "dark-grey"
    },
    color: {
      type: String,
      default: "primary"
    },
    link: {
      type: String,
      default: "/"
    },
    image: String,
    image2: String
  },
  computed: {
    imagePath: function() {
      return "images/themes/ET/menu/" + this.image;
    },
    image2Path: function() {
      return "images/themes/ET/menu/" + this.image2;
    },
    colMode: function() {
      let fieldName = "col-sm-" + this.size;
      return {
        [fieldName]: this.size > 0,
        "col-sm": this.size == 0
      };
    },
    bgColor: function() {
      let fieldName = "bg-" + this.color;
      return {
        [fieldName]: !!this.color
      };
    },
    fgColor: function() {
      let fieldName = "text-" + this.textcolor;
      return {
        [fieldName]: !!this.textcolor
      };
    }
  }
};
</script>

<style lang="scss">
#container {
  @extend .p-1;
  @include media-breakpoint-up(sm) {
    height: 50vh;
  }

  width: 100vw;
  .menu-item {
    @extend .p-2;
    @extend .media;
    @extend .overflow-hidden;
    border-radius: 0.5em 0.5em 0.5em 0.5em;

    min-height: 4em;
    height: 18vh;
    @include media-breakpoint-up(sm) {
      height: 100%;
      text-align: center;
      display: block;
    }

    div {
      object-fit: contain;

      svg {
        width: 15vh;
        height: 15vh;
      }
      img {
        min-height: 3em;
        height: 13vh;
        object-fit: contain;
        @include media-breakpoint-up(sm) {
          margin-top: 17.5vh;
          height: 15vh;
          margin-bottom: 2em;
        }
      }
    }
    a {
      @extend .stretched-link;
      // @extend .text-light;
      @extend .font-weight-bold;
      @extend .media-body;
      margin-left: 0em;
      align-self: flex-end;
    }
  }
}
</style>
